import React from "react"
import {graphql, StaticQuery, Link, useStaticQuery} from "gatsby";

import {StaticImage} from 'gatsby-plugin-image'



const Footer = ({}) => {


  const data = useStaticQuery(graphql`
  query{
    allStrapiLegals {
      edges {
        node {
          Title
          Content
          slug
          strapiId
        }
      }
    }

    allJrmCategories {
      nodes {
          Name
          Id
      }
    }

    allJrmFilteredCategories {
      nodes {
          Id
      }
    }
  }
`)

  var FilteredCategories = []
  data.allJrmCategories.nodes.forEach((item, index) => {
    var exists = data.allJrmFilteredCategories.nodes.find(filteredItem => filteredItem.Id === item.Id)
    if(exists){
      FilteredCategories.push({
        Id: item.Id,
        Name: item.Name
      })
    }
  })
 

    return(

    <div className="">

        <section className="footer-section">

          <div className="footer-social">
            <a href="https://www.facebook.com/Mindfulmemorials/" target="_blank" ><span uk-icon="icon: facebook; ratio: 1.3"></span></a>
            {/* <a href="#twitter" target="_blank"><FaTwitter size="1.5em"></FaTwitter></a> */}
            <a href="https://www.instagram.com/mindfulmemorials/" target="_blank"><span uk-icon="icon: instagram; ratio: 1.3"></span></a>
          </div>
          <div className="footer-wrapper">
              

              <div data-uk-grid>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="col-title">Mindful Memorials</div>
                      <ul>
                          <li><Link to={"/about-us"}>About us (A Family Journey)</Link></li>
                          {/* <li><Link to={'#0'}>News &amp; Events</Link></li> */}
                          {/* <li><Link to={"/contact"}>Customer Stories</Link></li> */}
                          {/* <li><Link to={'/sitemap'}>Site Map</Link></li> */}
                      </ul>
                  </div>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="col-title">Buying Guide</div>
                      <ul>
                          {/* <li><a href="#0">FAQs</a></li> */}
                          {data.allStrapiLegals.edges.map((item, index) => {
                            
                            return(<li key={index}><Link to={'/' + item.node.slug}>{item.node.Title}</Link></li>)
                          })}
                          {/* <li><a href="#0">Shipping &amp; Returns</a></li> */}
                      </ul>
                    <div className="col-title">Services &amp; Support</div>
                      <ul>
                          <li><Link to="/services/ornamentation">Ornamentation</Link></li>
                          <li><Link to="/services/design-options">Design options</Link></li>
                          <li><Link to="/services/bespoke-design">Bespoke design</Link></li>
                          <li><Link to="/services/additional-inscriptions">Additional Inscriptions</Link></li>
                          <li><Link to="/services/renovations">Renovations</Link></li>
                      </ul>
                  </div>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="col-title">Our Products</div>
                      <ul>
                          {/* <li><a href="#0">List of Products Categories I think</a></li> */}
                          {FilteredCategories.map((item, index) => {
                              return(<li key={index}><Link to={"/product-list/" + item.Name.replace(/\s+/g, '-').toLowerCase()}>{item.Name.toLowerCase()}</Link></li>)
                          })}
                      </ul>
                  </div>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="col-title">Contact</div>
                      <ul>
                          <li><a href="tel:+443330095133">Call us: +44 333 009 5133</a></li>
                          <li><Link to={"/contact"}>Find our Branches</Link></li>
                          <li><Link to={"/contact"}>Contact Us</Link></li>
                          {/* <li><a href="/get-quote">Make an Appointment</a></li> */}
                          {/* <li><a href="/#0">Get a Quote</a></li> */}
                      </ul>
                  </div>
                  
              </div>
          </div>
          <div className="footer-affiliates">
            <a href="https://www.namm.org.uk/" target="_blank" rel="noreferrer">
            <StaticImage src="../assets/img/logo_namm.png" alt='The National Association of Memorial Masons logo' width={150} height={168}/>
            {/* <img src={nammImage} width="150" alt="The National Association of Memorial Masons logo"></img> */}</a>
          </div>
        </section>

        <footer>
          <div className=" footer-wrapper">
            {/* <div className="footer-left">© Mindful Memorials {new Date().getFullYear()}</div>
            <div className="footer-right">Powered by Phaeria</div> */}
            Copyright © Mindful Memorials {new Date().getFullYear()}
            {data.allStrapiLegals.edges.map((item, index) => {             
              return(<span key={index}> | <Link to={'/' + item.node.slug}>{item.node.Title}</Link></span>)
            })}
          </div>

          
        </footer>
    </div>
      )

    
}

export default Footer