/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: "preconnect",
          href:
            "https://cdn.jsdelivr.net",
        },
        {
          rel: "preconnect",
          href:
            "https://cloud.typography.com",
        },
        {
          rel: "preconnect",
          href:
            "https://analytics.google.com",
        },
        {
          rel: "preconnect",
          href:
            "https://fonts.gstatic.com",
        },
        {
          rel: "preload",
          href: "https://cloud.typography.com/6192072/7261032/css/fonts.css",
          as: "style"
        },
        {
          rel: "stylesheet",
          href: "https://cloud.typography.com/6192072/7261032/css/fonts.css",
          async: true
        }
         
      ]}
      script={[  
        // for 3rd party js scripts - add src in.
        // {
        //   src:
        //     "",
        // },
      ]}
      
    >
      <meta key="key-description" name="description" content={metaDescription} />
      <meta key="key-ogtitle" name="og:title" content={title} />
      <meta key="key-ogdescription" name="og:description" content={metaDescription} />
      <meta key="key-ogtype" name="og:type" content="website" />
      <meta key="key-description" name="twitter:card" content="summary" />
      <meta key="key-twittercreateor" name="twitter:creator" content={site.siteMetadata.author} />
      <meta key="key-twittertitle" name="twitter:title" content={title} />
      <meta key="key-twitterdesc" name="twitter:description" content={metaDescription} />
 
      
     
      
      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
